import React from "react";
import { isMobileDevice } from "../../lib/getDeviceInfo";
import downloadBanner from "./img/download-banner.jpg";
import btnWindows from "./img/btn-windows.jpg";
import btnMac from "./img/btn-mac.jpg";

import "./league.scss";

const DOWNLOAD_MAC_LINK = "https://lol.secure.dyn.riotcdn.net/channels/public/x/installer/current/live.kr.zip";

const messages = () => {
  alert("모바일에서는 플레이 할 수 없습니다.");
};

const LeagueMac = () => {
  const isAvailableDownload = !isMobileDevice;

  return (
    <div className="download-page league">
      <div className="d_head mobile_show">
        <h2>
          <span className="title">게임 다운로드</span>
          <span className="eng_title">DOWNLOAD</span>
        </h2>
      </div>
      <div className="d_section section1">
        <div className="sec1_top pc_show">
          <div className="downs">
            {isAvailableDownload ? (
              <a className="pc_show" href={DOWNLOAD_MAC_LINK}>
                <img src={downloadBanner} alt="다운로드" />
              </a>
            ) : (
              <span className="pc_show" onClick={messages}>
                <img src={downloadBanner} alt="다운로드" />
              </span>
            )}
          </div>
        </div>

        <div className="os-navigator">
          <a href="/league">
            <img src={btnWindows} alt="windows 다운로드" />
          </a>
          <a href="/league/mac" className="active">
            <img src={btnMac} alt="mac 다운로드" />
          </a>
        </div>

        <div className="sec1_cont sc_table no_border">
          <h4>권장 시스템 사양</h4>

          <div className="sec1_contInner pc">
            <table cellSpacing="0" cellPadding="0" summary="권장 시스템 사양">
              <colgroup>
                <col width="20%" />
                <col width="40%" />
                <col width="40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>권장 시스템 사양</th>
                  <th>최소사양</th>
                  <th>권장사양</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>운영체제</th>
                  <td>MacOS 10.12 (x64)</td>
                  <td>MacOS 11 (x64)</td>
                </tr>
                <tr>
                  <th>CPU</th>
                  <td>
                    Intel: Core i5-750
                    <br />
                    ARM: 미지원
                  </td>
                  <td>
                    Intel: Core i5-3300
                    <br />
                    ARM: 미지원
                  </td>
                </tr>
                <tr>
                  <th>CPU 기능</th>
                  <td>SSE2</td>
                  <td>SSE3</td>
                </tr>
                <tr>
                  <th>메모리(RAM)</th>
                  <td>2GB</td>
                  <td>4GB</td>
                </tr>
                <tr>
                  <th>여유 저장 공간</th>
                  <td>HDD 12GB 이상</td>
                  <td>SSD 16GB 이상</td>
                </tr>
                <tr>
                  <th>그래픽 카드</th>
                  <td>
                    AMD: HD 6570
                    <br />
                    Intel: Intel HD 4600 내장 그래픽
                    <br />
                    (비디오 메모리 1GB 이상)
                  </td>
                  <td>
                    AMD: HD 6950
                    <br />
                    Intel: Intel UHD 630 내장 그래픽
                    <br />
                    (비디오 메모리 2GB 이상)
                  </td>
                </tr>
                <tr>
                  <th>그래픽 카드 기능</th>
                  <td>다이렉트 X 10급 하드웨어</td>
                  <td>다이렉트 X 11급 하드웨어</td>
                </tr>
                <tr>
                  <th>권장 그래픽 설정</th>
                  <td>해상도: 1024x768, 품질: 낮음</td>
                  <td>해상도: 1920x1080, 품질: 높음</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="sec1_contInner mo">
            <table cellSpacing="0" cellPadding="0" summary="권장 시스템 사양">
              <caption>최소 시스템 사양</caption>
              <colgroup>
                <col width="30%" />
                <col width="70%" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>최소사양</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>운영체제</th>
                  <td>MacOS 10.12 (x64)</td>
                </tr>
                <tr>
                  <th>CPU</th>
                  <td>Intel: Core i5-750 ARM: 미지원</td>
                </tr>
                <tr>
                  <th>CPU 기능</th>
                  <td>SSE2</td>
                </tr>
                <tr>
                  <th>메모리(RAM)</th>
                  <td>2GB</td>
                </tr>
                <tr>
                  <th>여유 저장 공간</th>
                  <td>HDD 12GB 이상</td>
                </tr>
                <tr>
                  <th>그래픽 카드</th>
                  <td>
                    AMD: HD 6570
                    <br />
                    Intel: Intel HD 4600 내장 그래픽
                    <br />
                    (비디오 메모리 1GB 이상)
                  </td>
                </tr>
                <tr>
                  <th>그래픽 카드 기능</th>
                  <td>다이렉트 X 10급 하드웨어</td>
                  <td>다이렉트 X 11급 하드웨어</td>
                </tr>
                <tr>
                  <th>권장 그래픽 설정</th>
                  <td>해상도: 1024x768, 품질: 낮음</td>
                </tr>
              </tbody>
            </table>

            <table cellSpacing="0" cellPadding="0" summary="권장 시스템 사양">
              <caption>권장 시스템 사양</caption>
              <colgroup>
                <col width="30%" />
                <col width="70%" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>권장사양</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>운영체제</th>
                  <td>MacOS 11 (x64)</td>
                </tr>
                <tr>
                  <th>CPU</th>
                  <td>
                    Intel: Core i5-3300
                    <br />
                    ARM: 미지원
                  </td>
                </tr>
                <tr>
                  <th>CPU 기능</th>
                  <td>SSE3</td>
                </tr>
                <tr>
                  <th>메모리(RAM)</th>
                  <td>4GB</td>
                </tr>
                <tr>
                  <th>여유 저장 공간</th>
                  <td>SSD 16GB 이상</td>
                </tr>
                <tr>
                  <th>그래픽 카드</th>
                  <td>
                    AMD: HD 6950
                    <br />
                    Intel: Intel UHD 630 내장 그래픽
                    <br />
                    (비디오 메모리 2GB 이상)
                  </td>
                </tr>
                <tr>
                  <th>그래픽 카드 기능</th>
                  <td>다이렉트 X 11급 하드웨어</td>
                </tr>
                <tr>
                  <th>권장 그래픽 설정</th>
                  <td>해상도: 1920x1080, 품질: 높음</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d_section section2">
        <div className="sec1_cont">
          <h4>설치 방법</h4>
          <ol>
            <li>
              <p>
                <span className="text">
                  다운로드 버튼을 눌러 리그 오브 레전드 <br className="mobile_show" />
                  설치파일을 받으세요.
                </span>
              </p>
              <div className="step_wrap step1">
                <h5>
                  리그 오브 레전드 <br className="mobile_show" />
                  클라이언트 다운로드
                </h5>
                <p>
                  리그 오브 레전드 실행을 위해 클라이언트를 <br className="mobile_show" />
                  다운로드하여 설치하시기 바랍니다.
                </p>

                {isAvailableDownload ? (
                  <a className="download_btn pc_show" href={DOWNLOAD_MAC_LINK}>
                    다운로드
                  </a>
                ) : (
                  <span className="download_btn pc_show" onClick={messages}>
                    다운로드
                  </span>
                )}
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default LeagueMac;
