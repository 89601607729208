import React from "react";
import { isMobileDevice } from "../../lib/getDeviceInfo";
import "./lor.scss";
import downloadBanner from "./img/lor/download-banner.jpg";
import installStep1 from "./img/lor/install01.png";
import installStep2 from "./img/lor/install02.png";
import installStep3 from "./img/lor/install03.png";
import installStep4 from "./img/lor/install04.png";
import playGameImg from "./img/lor/playgame.png";

const LoR = () => {
  const isAvailableDownload = !isMobileDevice;
  const messages = () => {
    alert("레전드 오브 룬테라 모바일 출시를 기다려주세요!");
  };

  return (
    <div className="download-page lor">
      <div className="d_head mobile_show">
        <h2>
          <span className="title">게임 다운로드</span>
          <span className="eng_title">DOWNLOAD</span>
        </h2>
      </div>
      <div className="d_section section1">
        <div className="sec1_top pc_show">
          <div className="downs">
            {isAvailableDownload ? (
              <a
                className="pc_show"
                href="https://bacon.secure.dyn.riotcdn.net/channels/public/x/installer/current/live.live.asia.exe"
              >
                <img src={downloadBanner} alt="다운로드" />
              </a>
            ) : (
              <span className="pc_show" onClick={messages}>
                <img src={downloadBanner} alt="다운로드" />
              </span>
            )}
          </div>
        </div>
        <div className="sec1_cont sc_table no_border">
          <h4>권장 시스템 사양</h4>
          <div className="sec1_contInner pc">
            <table cellSpacing="0" cellPadding="0" summary="권장 시스템 사양">
              <caption className="hidden">최소 및 권장 시스템 사양</caption>
              <colgroup>
                <col width="20%" />
                <col width="40%" />
                <col width="40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>권장 시스템 사양</th>
                  <th>최소사양</th>
                  <th>권장사양</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>운영체제</th>
                  <td colSpan="2">
                    Windows 8.1 또는 10 정품
                    <br className="pc_show" /> (Mac OS는 지원하지 않습니다)
                  </td>
                </tr>
                <tr>
                  <th>CPU</th>
                  <td>CPU 3 GHz processor</td>
                  <td>intel Core i5-7200U</td>
                </tr>
                <tr>
                  <th>RAM</th>
                  <td>4GB</td>
                  <td>8GB</td>
                </tr>
                <tr>
                  <th>HDD</th>
                  <td colSpan="2">3GB 이상의 하드 디스크 여유 공간</td>
                </tr>
                <tr>
                  <th>그래픽 카드</th>
                  <td>512MB 이상 비디오 메모리, 전용 GPU가 적용된 그래픽 카드</td>
                  <td>Geforce GT730/740 또는 동급 그래픽 카드 이상</td>
                </tr>
                <tr>
                  <th>Direct X</th>
                  <td colSpan="2">다이렉트 X 9.0c 이상</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="sec1_contInner mo">
            <table cellSpacing="0" cellPadding="0" summary="권장 시스템 사양">
              <caption>최소 시스템 사양</caption>
              <colgroup>
                <col width="30%" />
                <col width="70%" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>최소사양</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>운영체제</th>
                  <td>Windows 8.1 또는 10 정품 (Mac OS는 지원하지 않습니다)</td>
                </tr>
                <tr>
                  <th>CPU</th>
                  <td>CPU 3 GHz processor</td>
                </tr>
                <tr>
                  <th>RAM</th>
                  <td>4GB</td>
                </tr>
                <tr>
                  <th>HDD</th>
                  <td>3GB 이상의 여유공간</td>
                </tr>
                <tr>
                  <th>그래픽 카드</th>
                  <td>512MB 이상 비디오 메모리, 전용 GPU가 적용된 그래픽 카드</td>
                </tr>
                <tr>
                  <th>Direct X</th>
                  <td>다이렉트 X 9.0c 이상</td>
                </tr>
              </tbody>
            </table>

            <table cellSpacing="0" cellPadding="0" summary="권장 시스템 사양">
              <caption>권장 시스템 사양</caption>
              <colgroup>
                <col width="30%" />
                <col width="70%" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>권장사양</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>운영체제</th>
                  <td>Windows 8.1 또는 10 정품 (Mac OS는 지원하지 않습니다)</td>
                </tr>
                <tr>
                  <th>CPU</th>
                  <td>intel Core i5-7200U</td>
                </tr>
                <tr>
                  <th>RAM</th>
                  <td>8GB</td>
                </tr>
                <tr>
                  <th>
                    저장장치 <br />
                    (HDD 또는 SSD)
                  </th>
                  <td>3GB 이상의 여유공간</td>
                </tr>
                <tr>
                  <th>그래픽 카드</th>
                  <td>Geforce GT730/740 또는 동급 그래픽 카드 이상</td>
                </tr>
                <tr>
                  <th>Direct X</th>
                  <td>다이렉트 X 9.0c 이상</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="d_section section2">
        <div className="sec1_cont">
          <h4>설치 방법</h4>
          <ol>
            <li>
              <p>
                <span className="num">01</span>
                <span className="text">
                  다운로드 버튼을 눌러 레전드 오브 룬테라 <br className="mobile_show" />
                  설치파일을 받으세요.
                </span>
              </p>
              <div className="step_wrap step1">
                <h5>
                  레전드 오브 룬테라 <br className="mobile_show" />
                  클라이언트 다운로드
                </h5>
                <p>
                  레전드 오브 룬테라 실행을 위해 클라이언트를 <br className="mobile_show" />
                  다운로드하여 설치하시기 바랍니다.
                </p>

                {isAvailableDownload ? (
                  <a
                    className="download_btn pc_show"
                    href="https://bacon.secure.dyn.riotcdn.net/channels/public/x/installer/current/live.live.asia.exe"
                  >
                    다운로드
                  </a>
                ) : (
                  <span className="download_btn pc_show" onClick={messages}>
                    다운로드
                  </span>
                )}
              </div>
            </li>
            <li>
              <p>
                <span className="num">02</span>
                <span className="text">레전드 오브 룬테라 설치 파일을 실행해 주세요.</span>
              </p>
              <div className="step_wrap step2">
                <ol>
                  <li>
                    <div>
                      <p>1) 설치 마법사 시작</p>
                      <img src={installStep1} alt="1) 설치 마법사 시작" />
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>2) 로그인</p>
                      <img src={installStep2} alt="2) 로그인" />
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>3) 사용 약관 동의</p>
                      <img src={installStep3} alt="3) 사용 약관 동의" />
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>4) 게임 플레이</p>
                      <img src={installStep4} alt="4) 게임 플레이" />
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <p>
                <span className="num">03</span>
                <span className="text">
                  설치 완료 후 , 바탕화면 또는 시작메뉴에서 <br className="mobile_show" />
                  레전드 오브 룬테라를 실행해 주세요.
                </span>
              </p>
              <div className="step_wrap step3">
                <img src={playGameImg} alt="레전드 오브 룬테라 실행" />
                <p>레전드 오브 룬테라</p>
              </div>
            </li>
          </ol>
        </div>
      </div>
      <div className="d_section section3">
        <div className="sec1_cont">
          <h4>삭제 방법</h4>
          <div className="text">
            레전드 오브 룬테라를 삭제하길 원하신다면 [제어판-프로그램 제거] 혹은, [Windows 설정 - 앱 - 앱 및 기능]에서
            &quot;Legend of Runeterra&quot;를 선택하여 프로그램을 삭제해 주시면 됩니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoR;
