import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoR from "./page/LoR";
import League from "./page/league";
import LeagueMac from "./page/league/mac";
import "./App.css";

const LOL_HOME = "//www.leagueoflegends.co.kr";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/league/mac">
          <LeagueMac />
        </Route>
        <Route path="/league">
          <League />
        </Route>
        <Route path="/lor">
          <LoR />
        </Route>
        {/* No match redirect to LoL home */}
        <Route
          component={() => {
            window && window.location.replace(`${LOL_HOME}`);
            return null;
          }}
        />
      </Switch>
    </Router>
  );
}

export default App;
